import Infosys from '../../../assets/Home/Partners/Infosys.png';
import FoxLogo from '../../../assets/Home/Partners/Fox-logo.png';
import LambdaTest from '../../../assets/Home/Partners/LambdaTest.png';
import Primemovers from '../../../assets/Home/Partners/Primemovers-logo.svg';
import vallift from '../../../assets/Home/Partners/vallift-logo.svg';
import Rn from '../../../assets/Home/Partners/RN.jpg';
import aufiero from '../../../assets/Home/Partners/aufiero.png';

import './Partners.css';

const Partners = () => {
  return (
    <div className='partners-container'>
      <h4><span style={{fontWeight:"800"}}>Our</span> partners</h4>
      <div className='partners-carousel'>
        <div className="partner-logos">
          <div className="partner-logo">
            <img src={Infosys} alt="Infosys" />
          </div>
          <div className="partner-logo">
            <img src={FoxLogo} alt="FoxLogo" />
          </div>
          <div className="partner-logo wide-logo">
            <img src={LambdaTest} alt="LambdaTest" />
          </div>
          <div className="partner-logo">
            <img src={Primemovers} alt="Primemovers" />
          </div>
          <div className="partner-logo small-logo">
            <img src={vallift} alt="vallift" />
          </div>
          <div className="partner-logo small-logo">
            <img src={Rn} alt="renova teeth" />
          </div>
          <div className="partner-logo wide-logo">
            <img src={aufiero} alt="renova teeth" />
          </div>
        </div>
        <div className="partner-logos">
          <div className="partner-logo">
            <img src={Infosys} alt="Infosys" />
          </div>
          <div className="partner-logo">
            <img src={FoxLogo} alt="FoxLogo" />
          </div>
          <div className="partner-logo wide-logo">
            <img src={LambdaTest} alt="LambdaTest" />
          </div>
          <div className="partner-logo">
            <img src={Primemovers} alt="Primemovers" />
          </div>
          <div className="partner-logo small-logo">
            <img src={vallift} alt="vallift" />
          </div>
          <div className="partner-logo small-logo">
            <img src={Rn} alt="renova teeth" />
          </div>
          <div className="partner-logo wide-logo">
            <img src={aufiero} alt="renova teeth" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners