import { Link } from 'react-router-dom';
import './GetInTouch.css';

const GetInTouch = () => {
  return (
    <div className='getintouch-container'>
        <h4><span style={{fontWeight:'800'}}>Need</span> assistance?</h4>
        <Link className='dark-btn' to='/contact'>Get in touch</Link>
    </div>
  )
}

export default GetInTouch