import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { BlogData } from '../../utils/blogs';
import LinkIcon from '../../assets/link-icon.svg';
import { motion } from 'framer-motion';

import { MdArticle } from "react-icons/md";
import { ImLinkedin } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { Helmet } from "react-helmet";

import './SingleBlog.css';
import Faq from '../Home/Faq/Faq';


const AuthorLinksStyle = {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#101828",
    padding: "10px 14px",
    borderRadius: "16px"
};

const CustomHtmlRenderer = ({ html }) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

const Table = ({ data }) => {
    const getColumnNames = (data) => {
        if (data.length === 0) return [];
        return Object.keys(data[0]);
    };

    const columns = getColumnNames(data);

    return (
        <div className='blog-table-container'>
            <table>
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th key={column}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((testCase, index) => (
                        <tr key={testCase.testCaseNumber || index}>
                            {columns.map((column) => (
                                <td key={column}>{testCase[column] !== undefined ? testCase[column] : 'N/A'}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const SingleBlog = ({ resize }) => {
    const { slug } = useParams();

    // Find the blog data by slug
    const blog = BlogData.find(blog => blog.slug === slug);

    if (!blog) {
        return (
            <section style={{ height: "500px", display: "flex", flexDirection: "column", gap: "21px", alignItems: "center", justifyContent: "center" }}>
                <h1 style={{ fontSize: "22px" }}>Blog not found!</h1>
                <Link
                    style={{ fontSize: "16px", textDecoration: "none", color: "#fff", backgroundColor: "#101828", padding: "10px 14px", borderRadius: "32px" }}
                    to='/blogs'
                >
                    Go back to Blogs
                </Link>
            </section>
        );
    }

    return (
        <motion.article
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ ease: [0.76, 0, 0.24, 1], duration: 1 }}
            className={`single-blog-container ${resize ? 'resize-blog' : ''}`}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>{blog.meta.title}</title>
                <meta name="description" content={blog.meta.des} />
                <link rel="canonical" href={`https://botgauge.com/blogs/${blog.slug}`} />
                <meta property="og:title" content={blog.meta.title} />
                <meta property="og:description" content={blog.meta.des} />
                <meta property="og:url" content={`https://botgauge.com/blogs/${blog.slug}`} />
                <meta property="og:type" content="article" />
            </Helmet>
            <nav className='back-to-blogs-button' style={{ marginBottom: "30px" }}>
                <Link style={{ fontSize: "16px", textDecoration: "none", display: "flex", alignItems: "center", gap: "4px" }} to='/blogs'>
                    <img src={LinkIcon} style={{ transform: 'rotate(-135deg)', filter: 'invert(13%) sepia(90%) saturate(5695%) hue-rotate(205deg) brightness(91%) contrast(85%)' }} alt="Back to Blogs" />
                    <span style={{ color: "#0036AF" }}>Blogs</span>
                </Link>
            </nav>

            {blog.img && <img className='single-blog-container__main-img' src={blog.img} alt={blog.title} />}
            <header className='single-blog-container__main-title'>
                <h1>{blog.title}</h1>

                {blog.tags && blog.tags.length > 0 && (
                    <div className="single-blog-container__main-title-tag">
                        {blog.tags.map((tag, i) => (
                            <span style={{ color: tag.textColor, backgroundColor: tag.bgColor }} key={i}>{tag.text}</span>
                        ))}
                    </div>
                )}
            </header>

            <section className='single-blog-container__main-long-description'>
                {blog.longDescription && (
                    <>
                        {typeof blog.longDescription.main === 'string' ? (
                            <p>{blog.longDescription.main}</p>
                        ) : blog.longDescription.main && Array.isArray(blog.longDescription.main) ? (
                            blog.longDescription.main.map((point, i) => (
                                <p key={i}>{point}</p>
                            ))
                        ) : null}

                        {blog.longDescription.points && blog.longDescription.points.map((point, i) => (
                            point.id && point.id.startsWith('customDiv') ? (
                                <CustomHtmlRenderer key={point.id} html={point.html} />
                            ) : (<section key={point.id || i}>
                                {point.pointTitle && <h2>{point.pointTitle}</h2>}
                                {typeof point.pointMain === 'string' ? (
                                    <p>{point.pointMain}</p>
                                ) : point.pointMain && Array.isArray(point.pointMain) ? (
                                    point.pointMain.map((pointItem, i) => (
                                        <p key={i}>{pointItem}</p>
                                    ))
                                ) : null}
                                {point.subPoints && point.subPoints.length > 0 && (
                                    <ul>
                                        {point.subPoints.map((subPoint, j) => (
                                            <li key={subPoint.id || j}>
                                                {subPoint.subPointTitle && <h3>{subPoint.subPointTitle}</h3>}
                                                {typeof subPoint.subPointMain === 'string' ? (
                                                    <p>{subPoint.subPointMain}</p>
                                                ) : subPoint.subPointMain && Array.isArray(subPoint.subPointMain) ? (
                                                    subPoint.subPointMain.map((subPointItem, i) => (
                                                        <p key={i}>{subPointItem}</p>
                                                    ))
                                                ) : null}
                                                {subPoint.table && subPoint.table.length > 0 && (
                                                    <Table data={subPoint.table} />
                                                )}
                                                {subPoint.subPoints && subPoint.subPoints.length > 0 && (
                                                    <ol>
                                                        {subPoint.subPoints.map((nestedSubPoint, k) => (
                                                            <li key={nestedSubPoint.id || k}>
                                                                {nestedSubPoint.subPointTitle && <h4>{nestedSubPoint.subPointTitle}</h4>}
                                                                {typeof nestedSubPoint.subPointMain === 'string' ? (
                                                                    <p>{nestedSubPoint.subPointMain}</p>
                                                                ) : nestedSubPoint.subPointMain && Array.isArray(nestedSubPoint.subPointMain) ? (
                                                                    nestedSubPoint.subPointMain.map((nestedPointItem, i) => (
                                                                        <p key={i}>{nestedPointItem}</p>
                                                                    ))
                                                                ) : null}
                                                                {nestedSubPoint.table && nestedSubPoint.table.length > 0 && (
                                                                    <Table data={nestedSubPoint.table} />
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ol>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </section>)
                        ))}
                    </>
                )}
            </section>

            {blog.longDescription && blog.longDescription.credits && blog.longDescription.credits.length > 0 && (
                <footer className='blog-credits'>
                    <h2>Author:</h2>
                    <ul>
                        {blog.longDescription.credits.map((credit, i) => (
                            <li key={i}>
                                {credit.author && <span style={{ fontSize: "16px", fontWeight: "500" }}>{credit.author}</span>}
                                {credit.bio && <p style={{ opacity: "0.8" }}>{credit.bio}</p>}
                                <div style={{ display: "flex", gap: '10px' }}>
                                    {credit.articleURL && <a style={{ ...AuthorLinksStyle }} target='_blank' rel="noopener noreferrer" href={credit.articleURL}><MdArticle /></a>}
                                    {credit.email && <a style={{ ...AuthorLinksStyle }} href={`mailto:${credit.email}`}><MdEmail /></a>}
                                    {credit.linkedin && <a style={{ ...AuthorLinksStyle }} target='_blank' rel="noopener noreferrer" href={credit.linkedin}><ImLinkedin /></a>}
                                </div>
                            </li>
                        ))}
                    </ul>
                </footer>
            )}
            {blog.longDescription && blog.longDescription.ref && (
                <footer className='blog-reference'>
                    <h2>References:</h2>
                    <a target='_blank' rel="noopener noreferrer" href={blog.longDescription.ref}>Link to Article</a>
                </footer>
            )}
            {blog.longDescription && blog.longDescription.faq && blog.longDescription.faq.length > 0 && (
                <Faq FaqData={blog.longDescription.faq} />
            )}
        </motion.article>
    );
};

export default SingleBlog;