import { Link } from 'react-router-dom';
import './Blogs.css';
import { BlogData } from '../../../utils/blogs';

const HomeBlogCard = (blog) => {

  return (
    <Link to={`/blogs/${blog.slug}`} className="home-blog-card">
      <div className="blog-card-img">
        <img src={blog.img} alt="blog" />
      </div>
      <div className="blog-card-content">
        <p>{blog.title}</p>
      </div>
    </Link>
  )
}

const Blogs = () => {

  const recentBlogs = BlogData.slice(-4).reverse();

  return (
    <div className="blogs-container">
        <div className='blogs-title'>
            <p>Read our blogs</p>
        </div>
        <div className='blogs-header'>
          <h4>Blogs</h4>
        </div>
        <p>Powerful tools for sharing insights, knowledge, and stories. Enhance communication, build communities, and establish authority in your niche.</p>
        <div className="blogs-main">
          {recentBlogs.map((blog, index) => (
            <HomeBlogCard key={index} {...blog} />
          ))}
        </div>
        <Link className='light-btn' to='/blogs'>Read more</Link>
    </div>
  )
}

export default Blogs