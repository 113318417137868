import { useState } from 'react';
import { motion } from 'framer-motion';
import JotformEmbed from 'react-jotform-embed';
import spinnerDots from '../../assets/svg-spinners-3-dots-move.svg';

import './ContactUs.css';
import { Helmet } from 'react-helmet';


const Loader = () => {
    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "88vh" }}>
            <img src={spinnerDots} alt="loading" />
        </div>
    )
}

const ContactUs = () => {
    const [jotformLoaded, setJotformLoaded] = useState(false);
    setTimeout(() => {
        setJotformLoaded(true);
    }, 2000);

    return (
        <motion.div
            className={`contact-container`}
            style={{ minHeight: "100vh" }}
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ ease: [0.76, 0, 0.24, 1], duration: 1 }}
        >
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="Get in touch with BotGauge. We're here to answer your questions and provide support for all your AI evaluation needs." />
                <link rel="canonical" href="https://botgauge.com/contact" />
                <meta property="og:title" content="Contact BotGauge" />
                <meta property="og:description" content="Reach out to BotGauge for any inquiries or support." />
                <meta property="og:url" content="https://botgauge.com/contact" />
                <meta property="og:type" content="website" />
            </Helmet>
            {!jotformLoaded && <Loader />}
            {jotformLoaded && <JotformEmbed src="https://form.jotform.com/241921262647457" />}
        </motion.div>
    )
}

export default ContactUs;
