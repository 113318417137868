import { botFeaturesData } from '../../../utils/data';
import './botFeatures.css';

const BotFeaturesItem = ({ item }) => {
    return (
        <div className="bot-feature-item">
            <h4>{item.title}</h4>
            {item.description && (
                <p>{item.description}</p>
            )}
            {item.points && (
                <div className="bot-feature-points">
                    {
                        item.points.map((point, index) => (
                            <p key={index}>{point}</p>
                        ))
                    }
                </div>
            )}
        </div>
    );
}

const BotFeatures = () => {
    return (
        <div className="proposition-container botfeature-container">
            <div className='proposition-container-title'><p>Bot Features</p></div>
            <div className='proposition-container-header botfeature-header'>
                <h3>Get <span style={{ fontWeight: "800" }}>Visibility </span>and Smart Insights of Tests</h3>
            </div>
            <p>A personalized dashboard that provides smart insights into your latest test runs and key performance indicators (KPIs).</p>
            <div className="bot-feature-items">
                {
                    botFeaturesData.map((item, index) => (
                        <BotFeaturesItem key={index} item={item} />
                    ))
                }
            </div>
        </div>
    );
};

export default BotFeatures;
