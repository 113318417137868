import Customer1 from '../../../assets/Home/Feature/customer1.png';
import Customer2 from '../../../assets/Home/Feature/customer2.jpg';
import Customer3 from '../../../assets/Home/Feature/customer3.png';
import Customer4 from '../../../assets/Home/Feature/customer4.jpg';
import Customer5 from '../../../assets/Home/Feature/customer5.jpg';
import Customer6 from '../../../assets/Home/Feature/customer6.png';

import {motion} from 'framer-motion';

import './Feature.css';

const Feature = () => {
  return (
    <div className='feature-container'>
        <motion.div 
          initial={{ y: 60 }}
          whileInView={{  y: 0 }}
          transition={{ ease:[0, 0.55, 0.45, 1], duration:0.8 }}
          className="customers-registered-container btn">
            <img width={80} height={80} src={Customer1} alt="Customer1" />
            <img width={60} height={60} src={Customer2} alt="Customer2" />
            <img width={40} height={40} src={Customer3} alt="Customer3" />
            <h4>10,000+</h4>
            <p>Test Cases Generated</p>
            <img width={40} height={40} src={Customer4} alt="Customer4" />
            <img width={60} height={60} src={Customer5} alt="Customer5" />
            <img width={80} height={80} src={Customer6} alt="Customer6" />
        </motion.div>

        <div className="feature-main">
            <div>
                <p>Feature</p>
            </div>
            <h3>All in <span style={{fontWeight:"800"}}>One Platform</span> - Multiple Use Cases</h3>
            <p>Gen AI Low-Code Test Automation Tool for comprehensive automation of web-based applications, covering end-to-end testing across API, database, functional, visual, and UI.</p>
        </div>
    </div>
  )
}

export default Feature