import { Link } from 'react-router-dom';
import { footerLinks } from '../../utils/data';
import linkedin from '../../assets/linkedin-icon.svg';
import twitter from '../../assets/twitter-icon.svg';

import './Footer.css';


const FooterSection = ({ title, links }) => {
  const handleClick = (linkTitle) => {
    if (linkTitle === "Careers") {
      console.log("Careers");
      window.open("https://in.indeed.com/cmp/Botgauge/jobs", "_blank");
    }
  };
  return (
    <div className='footer-section'>
      <p>{title}</p>
      {links.map((link, index) => (
        <Link key={index} onClick={()=>handleClick(link.title)} to={link.path}>{link.title} {link.new && <span>New</span>}</Link>
      ))}
    </div>
  )
}

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='footer-top'>
        <div className='footer-links'>
          {footerLinks.map((section, index) => (
            <FooterSection key={index} title={section.title} links={section.links} />
          ))}
        </div>
        <div className='footer-address'>
          <p>Address:</p>
          <h4>1111B S Governors Ave STE 3608</h4>
          <h4>Dover, DE 19904</h4>
          <h4><a href="mailto:contact@botgauge.com">contact@botgauge.com</a></h4>
        </div>

      </div>
      <div className='footer-bottom'>
          <p>© 2024 BotGauge | <Link to="/privacy">Privacy</Link> | <Link to="/terms">Terms</Link> | Security</p>
          <div className='footer-social-media'>
            <a target='_blank' href="https://www.linkedin.com/company/botgauge/"><img src={linkedin} alt="Linkedin" /></a>
            <a target='_blank' href="https://twitter.com/BotGauge"><img src={twitter} alt="Twitter" /></a>
          </div>
      </div>
    </div>
  )
}

export default Footer
