import { motion } from 'framer-motion';
import './PropositionItem.css';

const PropositionItem = ({img,title,description}) => {
  return (
    <motion.div 
      initial={{ y: 50 }}
      whileInView={{  y: 0 }}
      transition={{ ease:[0, 0.55, 0.45, 1], duration:0.8 }}
      className="proposition-item-container">
        <img src={img} alt="" />
        <h4>{title}</h4>
        <p>{description}</p>
    </motion.div>
  )
}

export default PropositionItem