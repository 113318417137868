import { useEffect, useState } from 'react';
import LinkIcon from '../../../assets/link-icon.svg'
import './BlogTemplate.css';

const BlogTemplate = ({ width = 'auto', img, imgWidth, imgHeight, id, author, title, des, tags, onclick }) => {

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const containerStyle = {
    width: isSmallScreen ? '80vw' : width,
  };

  return (
    <div className='blog-template-container' style={containerStyle} onClick={onclick} >
      <img style={{ width: "100%", height: `${imgHeight}`, objectFit: "cover", overflow: "hidden" }} src={img} alt={`Blog ${id}`} />
      <div>
        <p className='blog-auth'>{author}</p>
        <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
          <h6>{title}</h6>
          <img src={LinkIcon} alt='link' />
        </div>
        <p className='blog-template-des'>{des}</p>
      </div>
      <div className='blog-tags'>
        {tags?.map((tag, i) => (
          <span key={i} style={{ color: tag.textColor, backgroundColor: tag.bgColor }}>{tag.text}</span>
        ))}
      </div>
    </div>
  )
}

export default BlogTemplate