import { useState } from 'react';
import Done from '../../assets/sucessful-logo.svg';
import './RequestDemo.css';
import { Link } from 'react-router-dom';
import bgPhoto from '../../assets/Home/Hero/hero-bg.png';

import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';


const RequestDemo = ({ resize }) => {
    const [submitted, setSubmitted] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        designation: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://faas-sfo3-7872a1dd.doserverless.co/api/v1/web/fn-980b5bf2-d3f6-4b9f-999f-067be2e5b1cb/default/gist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                console.log('Email sent successfully');
                setSubmitted(true);
                // Optionally, reset the form fields
                setFormData({ name: '', designation: '', email: '', phone: '', message: '' });
            } else {
                console.error('Failed to send email');
            }
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    return (
        <>
            <motion.div
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ ease: [0.76, 0, 0.24, 1], duration: 1 }}
                style={{ width: "100%", backgroundImage: `url(${bgPhoto})` }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Request a Demo</title>
                    <meta name="description" content="" />
                    <link rel="canonical" href={`https://botgauge.com/request-a-demo`} />
                </Helmet>
                <div

                    className='demopage__container'>

                    {submitted ?
                        <div className='demopage__submitted-container'>
                            <div className='demopage__submitted-container-text'>
                                <img src={Done} alt='done' />
                                <h5>Thank you for kind interest !</h5>
                                <p>Your demo request has been received. We'll be in touch shortly to schedule your demo. Kindly check you email for updates.</p>
                                <Link to='/'>Home</Link>
                            </div>
                        </div>
                        :
                        <>
                            <div
                                className={`demopage__title ${resize && 'resize-demopage'}`}
                            >
                                <h3>Request a <span style={{ fontWeight: "800" }}>Demo</span></h3>
                                <p>Request a personalised demo from our product experts</p>
                            </div>
                            <div className='demopage__form'>
                                <form onSubmit={handleSubmit}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <div style={{ width: "48%" }} className="form-group">
                                            <label htmlFor="name">Name <span style={{ color: "#EF4444" }}>*</span></label>
                                            <input placeholder='Name' type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                                        </div>
                                        <div style={{ width: "48%" }} className="form-group">
                                            <label htmlFor="designation">Designation <span style={{ color: "#EF4444" }}>*</span></label>
                                            <input placeholder='Designation' type="text" id="designation" name="designation" value={formData.designation} onChange={handleChange} required />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Business Email <span style={{ color: "#EF4444" }}>*</span></label>
                                        <input placeholder='you@company.com' type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">Phone Number (optional)</label>
                                        <input placeholder='+91 (555) 000-XXXX' type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Message (optional)</label>
                                        <textarea id="message" name="message" value={formData.message} onChange={handleChange}></textarea>
                                    </div>
                                    <button className='demopage__form-button' type="submit">Request a Demo</button>
                                    <p>Learn how to increase your testing efficiency to 10x</p>
                                </form>
                            </div>
                        </>

                    }

                </div>
            </motion.div>

        </>
    )
}

export default RequestDemo;