
import { propositionItems } from '../../../utils/data';
import './Proposition.css';
import PropositionItem from './PropositionItem/PropositionItem';

const Proposition = () => {



  return (
    <div className="proposition-container">
        <div className='proposition-container-title'><p>Value proposition</p></div>
        <div className='proposition-container-header'>
          <h3><span style={{fontWeight:"800"}}>Accelerate</span> your testing process</h3>
        </div>
        <p>Optimise efficiency, enhance speed, automate tasks, streamline workflows, detect errors swiftly, ensure reliability, boost productivity, expedite development.</p>
        <div className="proposition-items">
            {propositionItems.map((item) => (
                <PropositionItem key={item.id} img={item.img} title={item.title} description={item.description} />
            ))}
        </div>
    </div>
  )
}

export default Proposition