import './BotgenFeature.css'
import { botgenFeatureData } from '../../../utils/data'
import { motion } from 'framer-motion';
import playIcon from '../../../assets/Home/SneakPeak/play.png';

const Feature = ({ feature, index }) => {

  const odd = index % 2 == 0

  return (
    <motion.div
      initial={{ y: 120 }}
      whileInView={{ y: 0 }}
      transition={{ ease: [0, 0.55, 0.45, 1], duration: 0.8 }}
      className={`botgen_feature-feature-container ${odd && "reverse right-text"}`}>
      <div className='botgen_feature-feature-img-container'>
        <img className='play-demo-video-product-page' src={playIcon} alt="play demo video" />
        <img src={feature.img} height={300} width={300} alt="" />
      </div>
      <div className='botgen_feature-feature-main'>
        <h4>{feature.title}</h4>
        {feature.description && <p>{feature.description}</p>}
        {feature.points && feature.points.length > 0 && (
          <div className='botgen_feature-feature-main-point-continer'>
            {feature.points.map((point, index) => (
              <p>{point}</p>
            ))}
          </div>
        )}
      </div>
    </motion.div>
  )
}


const BotgenFeature = () => {
  return (
    <div className='pricing-container'>
      <div className='pricing-title'>
        <p>BotGen Feature</p>
      </div>
      <h4><span style={{ fontWeight: "800" }}>Migrate </span>your test cases effortlessly</h4>
      <div
        initial={{ y: 100 }}
        whileInView={{ y: 0 }}
        transition={{ duration: 1 }}
        className='botgen_feature-main'>
        {botgenFeatureData.map((feature, index) => (
          <Feature key={feature.id} index={index} feature={feature} />
        ))}
      </div>
    </div>
  )
}

export default BotgenFeature