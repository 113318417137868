import './ProductsHero.css';

import '../../Home/Hero/Hero.css';


import bgPhoto from "../../../assets/Home/Hero/hero-bg.png";
import AI from "../../../assets/Home/Hero/Generative-AI-powered.png";
import playIcon from "../../../assets/Home/Hero/play-icon.svg";
import rightArrow from "../../../assets/Home/Hero/arrow-right-icon.svg";
import gif from "../../../assets/Home/Hero/gif.gif";

import gsap from "gsap";
import SplitTextJS from 'split-text-js';


import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

import { useEffect } from "react";

const ProductsHero = ({ resize }) => {

  useEffect(() => {
    const titles = gsap.utils.toArray(".dynamic-text-wrapper p");
    const tl = gsap.timeline(); // Create a timeline

    titles.forEach((title, index) => {
      const splitTitle = new SplitTextJS(title);

      tl
        .from(splitTitle.chars, {
          opacity: 0,
          y: "-1rem",
          rotateX: -90,
          stagger: 0.02,
          delay:0
        }, "<")
        .to(splitTitle.chars, {
          opacity: 1,
          duration: 0.7,
        }, "<1")
        .to(splitTitle.chars, {
          opacity: 0,
          y: "1rem",
          rotateX: 90,
        }, "<1");
    });

    tl.repeat(-1); 
  }, []);




  return (
    <div
      className={`hero-container product-hero-container`}
      style={{ backgroundImage: `url(${bgPhoto})` }}
    >
      {/* <div className={`hero-main  ${resize && "resize-hero"}`}> */}
      <div className={`hero-main products-hero-main resize-hero`}>
        <div className="hero-main-left">
          <img
            style={{ borderRadius: "32px" }}
            width={200}
            src={AI}
            alt="Generative AI-powered"
          />

          <div className="hero-main-left-text">
            <div className="hero-main-left-text-main">
              <div className="hero-main-left-text-main-container products-hero-main-text">
                <p>GenAI End-to-End Test Automation Platform</p>
              </div>
            </div>
            <p>
            Leverage GenAI to streamline and expedite test automation. Generate test cases, perform smart healing, generate detailed reports, and manage your testing operations all from one platform.
            </p>
            <div className="hero-main-buttons">
              <Link
                // to="https://calendly.com/botgauge/30min"
                to="/contact"
                className="hero-main-button book-a-demo btn"
              >
                <p>Book a demo</p>
                <img src={playIcon} alt="play-icon" />
              </Link>
              <Link
                to="/contact"
                className="hero-main-button btn"
              >
                {/* <p>Get Started</p> */}
                <p>Contact Us</p>
                <img src={rightArrow} alt="right-arrow" />
              </Link>
            </div>
          </div>
        </div>

        <div className="hero-main-right">
          <img src={gif} alt="gif" />
        </div>
      </div>
    </div>
  );
};

export default ProductsHero;
