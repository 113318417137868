import React from 'react';
import './Terms.css'; // Assuming you have defined styles in Terms.css
import { TermsData, TermsPrivacyPolicy, termsTop } from '../../utils/data'; // Importing your data source
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';


export const TermsCompTop = ({ data }) => {
    return (
        <div className='TermsCompTop'>
            {data.map((item, index) => (
                <div key={index}>
                    <p><h4 className='terms-point-title-text'>{index + 1} {item.title}</h4> {item.description}</p>


                    {item.bulletPoints && item.bulletPoints.map((bulletPoint, bulletIndex) => (
                        <div className='bulletPoints'>
                            <p><h4 className='terms-point-title-text'>{`${index + 1}.${bulletIndex + 1}`} {bulletPoint.bulletPointTitle}</h4> {bulletPoint.bulletPointDes}</p>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export const TermsComp = ({ data }) => {
    return (
        <div>
            {data.map((item, index) => (
                <div key={index}>
                    <h4 className='terms-title-text'>{index + 1} {item.title}</h4>
                    {item.points.map((point, pointIndex) => (
                        <div key={pointIndex}>

                            <p className='terms-description-text'>
                                {point.pointTitle !== "" && (
                                    <h5 className='terms-point-title-text'>
                                        {index + 1}.{pointIndex + 1} {point.pointTitle}
                                    </h5>
                                )}{point.pointDescription}</p>
                            {point.bulletPoints && point.bulletPoints.map((bulletPoint, bulletIndex) => (
                                <p key={bulletIndex} className='terms-bullet-text'>- {bulletPoint}</p>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

const Terms = () => {
    return (
        <motion.div
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ ease: [0.76, 0, 0.24, 1], duration: 1 }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Terms</title>
                    <meta name="description" content="" />
                    <link rel="canonical" href={`https://botgauge.com/terms`} />
                </Helmet>
            <div className="feature-main terms-title">
                <h3>Terms of Service for BotGauge Inc</h3>
                {/* <p>Effective Date: 10th July 2024</p> */}
            </div>

            <div className='terms-container'>

                <div className='terms-title-top'>
                    <p>These Terms of Service ("Terms") outline the conditions under which BotGauge Inc. ("We," "Our," "Us") provides access to and use of Our Service(s) to individuals or entities who purchase Our Service(s) and/or create an Account with Us, along with their Users ("You," "Your," "Yourself"). By accessing and/or using Our Service(s), You agree to the following:</p>
                    <p>a) You agree to be bound by these Terms and acknowledge having read the Privacy Policy located at <Link style={{ fontWeight: "700" }} to="/privacy">Privacy Policy</Link>.</p>
                    <p>b) You warrant to Us that You are legally competent to enter into this agreement.</p>
                    <p>c) If You are entering into these Terms on behalf of any entity/company or its group, You possess the requisite authority to bind such entities, company, or its groups to these Terms.</p>
                    <p>If You do not agree to these Terms, You should immediately stop using Our Service(s).</p>
                    <p>You and Us will be individually referred to as a “Party” and collectively as “Parties.”</p>
                </div>

                <div>
                    <TermsCompTop data={termsTop} />
                </div>

                <div>
                    <TermsComp data={TermsData} />
                </div>

                <div className='terms-bottom'>
                    <h4 className='terms-title-text'>Contact Us</h4>
                    <p>If you have any questions about this Privacy Policy, please email us using our <Link style={{ fontWeight: "700" }} to="/contact">contact form</Link>.</p>
                </div>
            </div>
        </motion.div>
    );
};

export default Terms;
