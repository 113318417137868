import { Link } from 'react-router-dom';
import LaptopView from '../../../assets/Home/SneakPeak/laptop_giff.mp4';
import block from '../../../assets/Home/SneakPeak/cube.svg';
import arrows from '../../../assets/Home/SneakPeak/arrows.svg';
import playIcon from '../../../assets/Home/SneakPeak/play.png';
import videoSrc from '../../../assets/BotGauge_Cursor.mp4';
import crossIcon from '../../../assets/cross-icon.svg';

import { motion } from 'framer-motion';

import './SneakPeak.css';
import { useEffect, useRef, useState } from 'react';

const SneakPeak = () => {

    const [isVideoVisible, setIsVideoVisible] = useState(false);
    const [isVideoLoading, setIsVideoLoading] = useState(true);
    const videoRef = useRef(null);

    const handlePlayClick = () => {
        setIsVideoVisible(true);
    };

    const handleCloseClick = () => {
        setIsVideoVisible(false);
    };

    const handleVideoLoadedData = () => {
        setIsVideoLoading(false);
        if (videoRef.current) {
            videoRef.current.play(); 
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (videoRef.current && !videoRef.current.contains(event.target)) {
                setIsVideoVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div className="sneakpeak-container">
            <div className='sneakpeak-title'>
                <p>Looking into the hood</p>
            </div>
            <div className='sneakpeak-header'>
                <h3><span style={{ fontWeight: "800" }}>Sneak</span> peek</h3>
            </div>
            <p>Explore innovation and quality in our product. Uncover unique features that redefine expectations and elevate your experience. Discover excellence today!</p>
            <div className='sneakpeak-main'>
                <div className='sneakpeak-main-image'>
                    <img onClick={(handlePlayClick)} className='play-demo-video' src={playIcon} alt="play demo video" />
                    <video autoPlay loop muted src={LaptopView}></video>
                    {/* <img src={LaptopView} alt="demo video" /> */}
                </div>
                <div className='sneakpeak-main-blocks'>
                    <motion.div
                        initial={{ y: 120 }}
                        whileInView={{ y: 0 }}
                        transition={{ ease: [0, 0.55, 0.45, 1], duration: 0.8 }}
                        className='sneakpeak-main-block'>
                        <img src={block} alt="logo" />
                        <h4>20X</h4>
                        <p>Productivity increased !</p>
                    </motion.div>
                    <motion.div
                        initial={{ y: 120 }}
                        whileInView={{ y: 0 }}
                        transition={{ ease: [0, 0.55, 0.45, 1], duration: 0.8 }}
                        className='sneakpeak-main-block'>
                        <img src={arrows} alt="logo" />
                        <h4>85%</h4>
                        <p>Testing costs reduced !</p>
                    </motion.div>
                </div>
            </div>
            <Link to="https://calendly.com/botgauge/30min" className="sneakpeak-btn btn">Book a demo</Link>

            {isVideoVisible && (
                <div className="video-container">
                    <video className='' ref={videoRef} src={videoSrc} controls autoPlay onLoadedData={handleVideoLoadedData}></video>
                    <div className="close-button" onClick={handleCloseClick}>
                        <img src={crossIcon} alt="close video" />
                    </div>
                </div>
            )}
        </div>
    )
}

export default SneakPeak