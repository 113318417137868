import samplePng from '../assets/sample.png';
import ai_migrator from '../assets/Product/Auto-generation of test steps from user prompt.gif'
import release_changes from '../assets/Product/Manage Release Changes.gif'
import migrate_testcases from '../assets/Product/Migrate your test cases effortlessly.gif'


// Value Proposition
import logo1 from '../assets/Home/Proposition/logo1.png';
import logo2 from '../assets/Home/Proposition/logo2.svg';
import logo3 from '../assets/Home/Proposition/logo3.svg';
import logo4 from '../assets/Home/Proposition/logo4.svg';


import ComparisonLogo1 from '../assets/BotGauge-logo-white.png';
import ComparisonLogo2 from '../assets/Home/Comparison/autify.svg';
import ComparisonLogo3 from '../assets/Home/Comparison/eggplant.svg';
import ComparisonLogo4 from '../assets/Home/Comparison/katalon.svg';



// Home Navbar Links
export const navbarLinks = [
  {
    id: 1,
    title: 'Home',
    path: '/',
  },
  {
    id: 2,
    title: 'Product',
    path: '/product',
  },
  // {
  //   id: 3,
  //   title: 'Pricing',
  //   path: '/pricing',
  // },
  {
    id: 4,
    title: 'Blogs',
    path: '/blogs',
  },
  // {
  //   id: 5,
  //   title: 'Support',
  //   path: '/support',
  // },
  // {
  //   id: 6,
  //   title: 'Contact',
  //   path: '/contact',
  // },
]

// Home Navbar Updates
export const updates = [
  {
    id: "1",
    title: 'New version 1.0 has launched with new features and improvements.',
  },
  {
    id: "2",
    title: 'New version 2.0 has launched with new features and improvements.',
  },
  {
    id: "3",
    title: 'New version 3.0 has launched with new features and improvements.',
  },
  {
    id: "4",
    title: 'New version 4.0 has launched with new features and improvements.',
  },
  {
    id: "5",
    title: 'Register for Beta...',
  },
]

// Home Value Proposition
export const propositionItems = [
  {
    id: "1",
    img: logo1,
    title: "Accelerated test creation with Accuracy",
    description: "20x faster test case creation with precision using our Gen AI-powered testing authoring algorithm, complemented by a user-friendly UI.",
  },
  {
    id: "2",
    img: logo2,
    title: "Enhanced Test Coverage with Intelligent Suggestions",
    description: "Intelligently understands the application and suggests targeted scenarios for increased test coverage and early bug detection.",
  },
  {
    id: "3",
    img: logo3,
    title: "85% Reduction in total cost of test automation",
    description: "85% reduction in the total cost of test automation, providing substantial cost savings while maintaining high-quality testing standards.",
  },
  {
    id: "4",
    img: logo4,
    title: "Improved Release Efficiency with Smart Insights & Self-Healing",
    description: "Utilising advanced analytics and Self-Healing, BotGauge minimises manual intervention, reduces error rates, and accelerates the time-to-market.",
  },
]

// Home Comparison Data
export const comparisonData = [
  {
    Id: "1",
    type: "left",
    data: [
      {
        id: "1.1",
        text: "AI-Enhanced intelligent test case generation from comprehensive test plans and documentation",
      },
      {
        id: "1.2",
        text: "AI-LLM powered test authoring and auto suggestions",
      },
      {
        id: "1.3",
        text: "Real-time test case compilation and debugging",
      },
      {
        id: "1.4",
        text: "Collaborative knowledge integration and suggestion engine",
      },
      {
        id: "1.5",
        text: "AI-Enhanced visual component comparison",
      },
      {
        id: "1.6",
        text: "Auto-healing",
      },
      {
        id: "1.7",
        text: "Data driven testing",
      },
      {
        id: "1.8",
        text: "Tailored reporting and analytics capabilities",
      },
    ]
  },
  {
    id: '2',
    type: "right",
    data: [
      {
        id: '2.1',
        img: ComparisonLogo1,
        title: "botgauge",
        values: [true, true, true, true, true, true, true, true]
      },
      {
        id: '2.2',
        img: ComparisonLogo2,
        title: "autify",
        values: [false, false, false, false, true, true, false, false]
      },
      {
        id: '2.3',
        img: ComparisonLogo3,
        title: "eggplant",
        values: [false, false, false, false, true, false, true, false]
      },
      {
        id: '2.4',
        img: ComparisonLogo4,
        title: "katalon",
        values: [false, false, false, false, false, false, true, false]
      },
    ]
  }
]

// Home Plans
export const plans = [
  {
    id: "1",
    title: "Starter plan",
    price: "$ 250",
    duration: "per month",
    theme: "dark",
    path: "/request-a-demo",
    btnText: "Try it for free",
    features: [
      "Single license for test case creation",
      "24/7 Customer support",
      "Unlimited users & test cases",
      "Dedicated onboarding expert",
      "Premium support"
    ]
  },
  {
    id: "2",
    title: "Exclusive plan",
    price: "Custom",
    duration: "For organisations with growing complexity",
    theme: "",
    path: "/request-a-demo",
    btnText: "Contact us",
    // features:[
    //     "Single license for test case creation",
    //     "24/7 Customer support",
    //     "Unlimited users & test cases",
    //     "Dedicated onboarding expert",
    //     "Premium support"
    // ]
  },
]

// FAQ Data
export const FaqData = [
  {
    id: 1,
    question: "How can I get a free trial?",
    answer: "Yes, please sign up for a free trial ",
  },
  {
    id: 2,
    question: "Which all types of testing can be done using BotGauge?",
    answer: "UI, Functional, API, DB and visual testing can be done using BotGauge.",
  },
  {
    id: 3,
    question: "Which all devices can we test in?",
    answer: "Web based application as of now. We will be supporting Mobile app testing soon.",
  },
  {
    id: 4,
    question: "Can I integrate Botgauge into our CI/CD pipeline?",
    answer: "Yes, we will start supporting available CI/CD tools. Meanwhile you can use our exposed APIs to integrate it with your apps.",
  },
  {
    id: 5,
    question: "Where can I find more details about the tool?",
    answer: "Please send us a demo request",
  },
  {
    id: 6,
    question: "Can I run multiple tests in parallel?",
    answer: "Yes, you can run multiple tests in parallel based on your licence.",
  },
  {
    id: 7,
    question: "How many users can login per licence?",
    answer: "Unlimited users can login per licence.",
  },
]


// Footer Data
export const footerLinks = [
  {
    id: "1",
    title: "Features",
    links: [
      {
        id: "1.1",
        title: "Gen AI Suggestive Test Creation",
        path: "/product",
      },
      {
        id: "1.2",
        new: true,
        title: "Web App testing",
        path: "/product",
      },
      {
        id: "1.3",
        title: "API Testing",
        path: "/product",
      },
      {
        id: "1.4",
        title: "Multi browser tests",
        path: "/product",
      },
    ]
  },
  {
    id: "2",
    title: "Support",
    links: [
      {
        id: "2.1",
        title: "FAQ",
        path: "/",
      },
      {
        id: "2.2",
        title: "Bot Community",
        path: "/",
      },
    ]
  },
  {
    id: "3",
    title: "Documentation",
    links: [
      {
        id: "3.1",
        title: "User Guide",
        path: "/",
      },
      {
        id: "3.2",
        title: "Tutorial Videos",
        path: "https://www.youtube.com/watch?v=1gYkjjdSWOg",
      },
    ]
  },
  {
    id: "4",
    title: "Contact",
    links: [
      {
        id: "4.1",
        new: true,

        title: "Careers",
        path: "/",
      },
      {
        id: "4.2",
        // new:true,
        title: "Team",
        path: "/",
      },
      {
        id: "4.3",
        title: "Partners",
        path: "/",
      },
      {
        id: "4.4",
        title: "Contact Us",
        path: "/contact",
      },
      {
        id: "4.5",
        title: "Demo",
        path: "https://calendly.com/botgauge/30min",
      },
    ]
  },
]

// products
export const botgenFeatureData = [
  {
    id: "1",
    img: migrate_testcases,
    title: "AI Migrator: Transform Manual Test Cases and PRD into Automated Tests",
    description: "",
    points: [
      "Seamlessly create test cases from your manual test case files. Say goodbye to the tedious manual work and let our advanced AI handle the transformation",
      "Upload any type of file (.pdf, .docx, .xlsx)",
      "Single Document, Complete Coverage: Create comprehensive end-to-end test cases with just a single document upload",
    ]
  },
  {
    id: "2",
    img: ai_migrator,
    title: "Autogeneration of test steps from user prompt",
    description: "",
    points: [
      "Generate detailed test steps and fill out forms using dummy data, all powered by AI",
      "Create Negative scenarios using simple English prompts",
      "Utilise your existing Excel files to create steps and parameterize them for efficient testing",
    ]
  },
  {
    id: "3",
    img:  release_changes,
    title: "Manage Release Changes",
    description: "With BotGauge, you can update your existing test cases by simply uploading the latest release documents",
  }
]

// Bot Feature
export const botFeaturesData = [
  {
    id: "1",
    img: samplePng,
    title: "Develop test in plain English",
    description: "Effortlessly generate test cases with step-level compilation and interact directly with the application under test (AUT) using our built-in browser to identify issues during test case creation.",
    points: [
      "Create test cases using plain English language with the assistance of NLP",
      "Access a straightforward and intuitive interface for managing test data and executing tests seamlessly",
      "Eliminate the necessity for complex coding or scripting knowledge",
    ]
  },
  {
    id: "2",
    img: samplePng,
    title: "Better Coverage: Test on Multiple OS, Browser",
    description: "Effortlessly test browser compatibility for web applications, seamlessly integrated with top cloud platforms, streamlining the selection of preferred platform-browser combinations.",
    points: [
      "Conduct live cross-browser testing for both public and local websites and web apps",
      "Run automated browser tests on a scalable, secure automation cloud",
      "Ensure API functionality, reliability, and security for seamless software component communication",

    ]
  },
  {
    id: "3",
    img: samplePng,
    title: "Efficient Test Management",
    description: null,
    points: [
      "Efficiently manage test development and execution across diverse projects, applications, and versions",
      "Streamline resource allocation by prioritizing test cases to optimize resource management",
      "Effectively manage multiple projects and assign tasks to various users with customizable user roles",
    ]
  },
  {
    id: "4",
    img: samplePng,
    title: "Bulk Execution & Scheduler (Suites)",
    description: "Organize and manage test cases efficiently using our parallel execution engine, reducing feedback time",
    points: [
      "Group test cases for efficient management and faster feedback using our parallel execution engine",
      "Automate tests run hourly, daily, weekly, or monthly with our built-in scheduler",
      "Enhance testing productivity with precise scheduling and parallel execution capabilities",
    ]
  },
  {
    id: "5",
    img: samplePng,
    title: "Simplified Bug Analysis & Reporting",
    description: "AI-powered test execution insights for efficient bug detection and streamlined test case management",
    points: [
      "Access and review your execution, environment, and test case results all at one location",
      "One-click integration with test management platform to streamline annotating issues, assigning them to teammates",
      "Bulk execution reports provide an easy and comprehensive overview, facilitating effective management of test regression",
    ]
  }
]

// Terms
export const TermsData = [
  {
    id: "1",
    title: "Your Rights",
    points: [
      {
        pointTitle: "",
        pointDescription: "Subject to your compliance with these Terms and solely during the Subscription Term, you are granted a limited, non-exclusive, revocable right to access and use the Service(s) for your internal business purposes, as specified in the subscription plan outlined in the Order Form."
      },
    ]
  },
  {
    id: "2",
    title: "Your Responsibilities",
    points: [
      {
        pointTitle: "Your Account:",
        pointDescription: `Your access to and use of the Service(s) is limited to the number of individual Users specified in the relevant Order Form. Each User must use unique login information ("User Login") that is assigned to one individual. You are not allowed to transfer your Account to another person, nor use someone else's Account without the Account holder's permission.`,
      },
      {
        pointTitle: "Acceptable Use:",
        pointDescription: "You agree not to:",
        bulletPoints: [
          "License, sublicense, sell, resell, rent, lease, transfer, assign, distribute, timeshare, disassemble, reverse engineer, decompile, or make the Service(s) available to any third party, except for Users within your organization as permitted by these Terms.",
          "Modify, adapt, or hack the Service(s) or attempt unauthorized access to the Service(s) or related systems or networks.",
          "Use the Service(s) to store or transmit Sensitive Personal Information.",
          "Use the Service(s) to store or transmit Service Data in violation of applicable laws and regulations, including privacy rights and export control laws.",
          "Access the Service(s) for the purpose of creating derivative works, or developing or operating products or services for third parties in competition with the Service(s).",
          "Use the Service(s) to store or transmit any content that infringes on intellectual property rights or is unlawful, racist, hateful, abusive, libelous, obscene, or discriminatory.",
          "Use the Service(s) to knowingly post, transmit, upload, link to, send, or store any viruses, malware, trojan horses, time bombs, or other harmful software.",
          '"Crawl," "scrape," or "spider" any page, data, or portion of the Service(s) using manual or automated means.',
        ]
      },
      {
        pointTitle: "",
        pointDescription: "If We notify you that a specific activity or purpose is prohibited with respect to the Service(s), you must immediately cease using the Service(s) for that activity or purpose."
      },
      {
        pointTitle: "",
        pointDescription: "You represent and warrant that you have obtained and maintained all necessary authorizations, approvals, and permissions from Users and individuals with whom Users interact when using Third-party Service(s), allowing Us to process such Service Data to provide the Service(s)."
      },
      {
        pointTitle: "",
        pointDescription: "You represent and warrant that you are free to enter into these Terms and perform your obligations hereunder, and that doing so will not violate any other agreement to which you are a party, including any agreements with Third-party Service(s)."
      }
    ]
  },
  {
    id: "3",
    title: "Service(s)",
    points: [
      {
        pointTitle: "",
        pointDescription: `You may request a demo or trial of Our Service(s) by creating accounts for trial use for a limited period ("Trial Period"). The Trial Period is subject to these Terms and any additional terms we specify. We reserve the right, at our sole discretion, to terminate the Service(s) and Your right to use the Service(s) at any time during the Trial Period without liability to You.`
      },
      {
        pointTitle: "",
        pointDescription: `Any enhancements, new features, or updates ("Updates") to the Service(s) are also subject to these Terms, and We reserve the right to deploy Updates at any time.`
      },
      {
        pointTitle: "",
        pointDescription: `The Service(s) may be temporarily unavailable due to scheduled downtime for upgrades and maintenance. In such cases, We will use commercially reasonable efforts to notify You in advance.`
      },
    ]
  },
  {
    id: "4",
    title: "User Content",
    points: [
      {
        pointTitle: "",
        pointDescription: `We allow You to post content in the form of alphanumeric data. Any content You post, share, or otherwise make available through the Service(s), including all content related to software codes, is considered "User Generated Content." You retain all rights and are solely responsible for any and all User Generated Content You post, subject to these Terms. We do not play any role in User Generated Content.`
      },
      {
        pointTitle: "",
        pointDescription: "You shall:",
        bulletPoints: [
          "Be solely responsible for the accuracy, quality, integrity, legality, reliability, and appropriateness of all data and content You submit for Your use of the Service(s);",
          "Use commercially reasonable efforts to prevent unauthorized access or tampering, and notify Us promptly of any unauthorized use or security breach;",
          "Comply with all applicable local, state, central, and foreign laws (including laws regarding privacy and protection of personal or consumer information) when using the Service(s);",
          "Comply with all applicable rules of credit card associations (including American Express, MasterCard, and Visa), if applicable; and",
          "Obtain and maintain all computer hardware, software, and communications equipment needed to access the Service(s) and pay all access charges (e.g., ISP fees) incurred in connection with Your use of the Service(s)."
        ]
      },
      {
        pointTitle: "",
        pointDescription: "You grant Us and Our affiliates a non-exclusive, royalty-free, transferable, sublicensable, worldwide license to store, display, reproduce, and distribute Your User Generated Content for the purposes of operating, developing, and providing the Service(s) and for Our internal purposes. We reserve the right to remove User Generated Content for any reason, including violations or apparent violations of these Terms, as determined by Us. However, We do not retain the right to modify any User Generated Content, and shall not access the User Generated Content if such access is not required for the purposes of the Services rendered.",
      },
      {
        pointTitle: "",
        pointDescription: "Following the termination or deactivation of Your Account, or Your removal of any User Generated Content from Our Service(s), We may, but are not obligated to, retain such User Generated Content for a commercially reasonable period for backup, archival, or audit purposes. It is acknowledged and agreed that We have the right to delete all User Generated Content following the termination or deactivation of Your Account.",
      }
    ]
  },
  {
    id: "5",
    title: "Intellectual Property Rights",
    points: [
      {
        pointTitle: "",
        pointDescription: "Except for the rights granted to You under clause 1, all rights, title, and interest in and to all Intellectual Property Rights and/or proprietary rights shall belong to and remain exclusively with Us."
      },
      {
        pointTitle: "",
        pointDescription: "You own the rights to the Service Data that You provide to Us. We do not claim ownership over such Service Data. You grant Us a royalty-free license and right to use Service Data solely to provide, support, maintain, and improve the Service(s)."
      },
      {
        pointTitle: "",
        pointDescription: "Our Use of Anonymous Data: You agree that We may use data generated by and stored on Our servers anonymously for Our internal business purposes, including but not limited to the development of anonymous marketing and sales materials, statistical analysis, and publication in aggregated form in industry benchmark reports."
      },
      {
        pointTitle: "",
        pointDescription: "We shall have the right and license to incorporate into the Service(s) or otherwise use any suggestions, enhancement requests, recommendations, or other feedback We receive from You."
      },
      {
        pointTitle: "",
        pointDescription: "All rights not expressly provided to You herein are reserved."
      }
    ]
  },
  {
    id: "6",
    title: "Third Party Services",
    points: [
      {
        pointTitle: "",
        pointDescription: "The Service(s) enable integration with various Third-party Service(s). You acknowledge and agree that Your use of Third-party Service(s) will be subject to their terms, conditions, and privacy policies. We are not liable for Your enablement, access, or use of such Third-party Service(s), including Your data processed by such third parties. Our liability for Your data is limited to when it is being transmitted through the Service(s). For any issues with Third-party Service(s), You should contact the respective service provider."
      },
      {
        pointTitle: "",
        pointDescription: "When You authorize integration with Third-party Service(s), You permit Us to access, store, and process certain data provided by these services, as well as any other information they make available to Us, in accordance with these Terms."
      },
      {
        pointTitle: "",
        pointDescription: "You are responsible for authorizing the integration of Third-party Service(s) and ensuring the transmission of Service Data through the Service(s). We are not liable for the accuracy and sufficiency of Service Data submitted to and transmitted through the Service(s). You acknowledge and agree that We are not liable for claims arising from Your violation of this clause."
      }
    ]
  },
  {
    id: "7",
    title: "Charges and Payment",
    points: [
      {
        pointTitle: "Fees:",
        pointDescription: "All charges associated with Your Account are based on the subscribed plan and the prices listed in an Order Form (“Fees”). Details regarding such Fees are mentioned in an Order Form. Fees are due in full and payable in advance in accordance with clause 7.3 when You subscribe to the Service(s)."
      },
      {
        pointTitle: "Renewal:",
        pointDescription: "Unless Your Account and subscription to the Service(s) are terminated, Your subscription will renew for a Subscription Term equivalent to the expiring Subscription Term at the prevailing Fees."
      },
      {
        pointTitle: "Payment:",
        pointDescription: "You authorize Us or Our authorized agents to bill You upon subscription to the Service(s) (and any renewal). Unless stated otherwise in an Order Form, Your payment is due within thirty (30) days of Our invoice date."
      },
      {
        pointTitle: "Refunds:",
        pointDescription: "Unless specified otherwise in these Terms, all Subscription Charges are non-refundable. No refunds are issued for partial use or non-use of the Service(s).",
      },
      {
        pointTitle: "Late Payments/Non-payment of Fees:",
        pointDescription: "We will notify You if We do not receive payment by the due date. Payments must be received within ten (10) days of Our notice. If not received, We may (i) charge late payment interest at 1.5% per month, (ii) suspend Your access to the Service(s) until payment is received, and/or (iii) terminate Your Account."
      },
      {
        pointTitle: "Applicable Taxes:",
        pointDescription: "Unless otherwise stated, Fees do not include taxes, levies, duties, or similar governmental assessments, including value-added, sales, use, or withholding taxes. If You must withhold any amounts under law or tax regime, You will gross up payments so We receive the quoted and invoiced amount."
      }
    ]
  },
  {
    id: "8",
    title: "Term, Termination, and Suspension",
    points: [
      {
        pointTitle: "Subscription Term:",
        pointDescription: "The Subscription Term is set forth on the Website or in a relevant Order Form."
      },
      {
        pointTitle: "Termination by You:",
        pointDescription: "You may terminate Your Account(s) if We materially breach these Terms, provided You give advance notice of the breach and at least sixty (60) days to cure it. If terminated for this reason, We will pro-rata refund the Subscription Charges for the remainder of the Subscription Term."
      },
      {
        pointTitle: "Suspension and Termination by Us:",
        pointDescription: `In addition to suspension for late or non-payment of Fees, We may suspend Your access to Your Account or the Service(s) if You violate these Terms. We will notify You of the violation and, at Our discretion, provide a fifteen (15) day period ("Cure Period") to cure or cease such activities. If You fail to do so, or if We believe the breaches cannot be cured, Your Account/Service(s) will be terminated. We may also terminate a Trial Period as per clause 3.1. We reserve the right to terminate Your Account/Service(s) at any time for business reasons, including discontinuation of the Service(s), with written notice.`
      },
      {
        pointTitle: "Termination for Insolvency:",
        pointDescription: "Either Party may terminate these Terms without notice if the other Party becomes insolvent, makes an assignment for the benefit of creditors, is the subject of bankruptcy proceedings, or has a receiver or trustee appointed for its property."
      },
      {
        pointTitle: "Effect of Termination:",
        pointDescription: `Following termination of Your Account/Service(s) by You or Us, Your access to and use of the Service(s) will cease. We will retain all Service Data for sixty (60) days from the date of termination ("Data Retention Period"). After the Data Retention Period, We reserve the right to delete all Service Data.`
      }
    ]
  },
  {
    id: "9",
    title: "Confidentiality; Data Privacy and Security",
    points: [
      {
        pointTitle: "",
        pointDescription: "If You choose or are provided with a user identification code, login, password, or any other piece of information as part of Our security procedures, You must treat such information as confidential and not disclose it to any third party. We have the right to disable any user identification code or password if, in Our opinion, You have failed to comply with these Terms. We are not responsible for any activities, including access or loss of data, resulting from Your failure to comply with this clause."
      },
      {
        pointTitle: "",
        pointDescription: "Each Party will protect the other's Confidential Information from unauthorized use, access, or disclosure in the same manner as each protects its own Confidential Information, and at least with reasonable care. Each Party may use the other's Confidential Information solely to exercise rights and perform obligations under these Terms and disclose such information only to employees, representatives, and agents who need to know it and are bound to maintain its confidentiality."
      },
      {
        pointTitle: "",
        pointDescription: "We will process any Personal Data in the Service Data only on Your behalf as Your data processor, in accordance with these Terms and applicable data privacy laws."
      },
      {
        pointTitle: "",
        pointDescription: "You acknowledge that You are the Business, and We are the Service Provider regarding the Personal Information of Consumers (as understood under the California Consumer Privacy Act ('CCPA')) disclosed by You to Us for providing the Service(s)."
      },
      {
        pointTitle: "",
        pointDescription: "We will not sell, retain, use, or disclose the Personal Information of Consumers processed on Your behalf for any purpose other than providing the Service(s) under these Terms. We will comply with these restrictions."
      },
      {
        pointTitle: "",
        pointDescription: "You are responsible for providing the required notice to Consumers about sharing their Personal Information with Us."
      },
      {
        pointTitle: "",
        pointDescription: "The Personal Data We collect from You at the time of creating the Account, for billing purposes, and for Users accessing the Account will align with Our Privacy Policy."
      }
    ]
  },
  {
    id: "10",
    title: "Warranties",
    points: [
      {
        pointTitle: "",
        pointDescription: "WE WARRANT THAT THE SERVICE(S) WILL PERFORM IN ALL MATERIAL ASPECTS IN ACCORDANCE WITH THE DOCUMENTATION."
      },
      {
        pointTitle: "",
        pointDescription: "THE SERVICE(S), INCLUDING ALL SERVER AND NETWORK COMPONENTS, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. ALL EXPRESS OR IMPLIED REPRESENTATIONS AND WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, OR NON-INFRINGEMENT, ARE HEREBY EXCLUDED."
      },
      {
        pointTitle: "",
        pointDescription: "YOU ACKNOWLEDGE THAT WE DO NOT WARRANT THAT ACCESS TO THE SERVICE(S), PROVIDED OVER THE INTERNET AND VARIOUS TELECOMMUNICATIONS NETWORKS, ALL OF WHICH ARE BEYOND OUR CONTROL, WILL BE UNINTERRUPTED, TIMELY, SECURE, ACCURATE, ERROR-FREE, OR FREE FROM VIRUSES OR OTHER MALICIOUS SOFTWARE, OR THAT IT WILL BE SUITABLE FOR OR MEET YOUR REQUIREMENTS."
      }
    ]
  },
  {
    id: "11",
    title: "Limitation of Liability",
    points: [
      {
        pointTitle: "",
        pointDescription: "TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL EITHER PARTY BE LIABLE TO ANY PERSON FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, COVER, OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOST REVENUE, LOST SALES, LOST GOODWILL, LOSS OF USE OR LOST CONTENT, IMPACT ON BUSINESS, BUSINESS INTERRUPTION, LOSS OF ANTICIPATED SAVINGS, LOSS OF BUSINESS OPPORTUNITY) HOWEVER CAUSED, UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, CONTRACT, TORT, WARRANTY, BREACH OF STATUTORY DUTY, NEGLIGENCE, OR OTHERWISE, EVEN IF A PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR COULD HAVE FORESEEN SUCH DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR AGGREGATE LIABILITY AND THAT OF OUR AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS, AND LICENSORS, RELATING TO THE SERVICE(S), WILL BE LIMITED TO AN AMOUNT EQUAL TO TWELVE MONTHS OF THE SUBSCRIPTION CHARGES PAID BY YOU FOR THE SERVICE(S) PRIOR TO THE FIRST EVENT OR OCCURRENCE GIVING RISE TO SUCH LIABILITY."
      },
      {
        pointTitle: "",
        pointDescription: "IN JURISDICTIONS WHICH DO NOT PERMIT THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, OUR LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW."
      },
      {
        pointTitle: "",
        pointDescription: "NOTWITHSTANDING ANYTHING ELSE TO THE CONTRARY, WE DISCLAIM ALL LIABILITIES, TO THE MAXIMUM EXTENT PERMITTED BY LAW, WITH RESPECT TO THE SERVICES OFFERED DURING THE TRIAL PERIOD."
      }
    ]
  },
  {
    id: "12",
    title: "Indemnification",
    points: [
      {
        pointTitle: "Indemnification by you:",
        pointDescription: "You will indemnify and hold Us harmless against any claim brought by a third party against Us, Our respective employees, officers, directors, and agents arising from Your acts or omissions in connection with clause 2 of these Terms provided that (a) We promptly notify You of the threat or notice of such a claim, (b) You will have the sole and exclusive control and authority to select defense attorneys, defend and/or settle any such claim; and (c) We shall fully cooperate with You in connection therewith."
      },
      {
        pointTitle: "Indemnification by us:",
        pointDescription: "We will defend You from any third-party claim alleging that Your use of the Service(s) as contemplated hereunder infringes any third party’s patent, copyright, and/or trademark intellectual property rights (an 'IP Claim'), and will indemnify and hold You harmless from and against any damages and costs awarded against You, or agreed in settlement by Us (including reasonable attorneys’ fees) resulting from such IP Claim. We will have no liability or obligation with respect to any IP Claim if such claim is caused in whole or in part by (i) unauthorized use of the Service(s) by You or Your users; (ii) modification of the Service(s) by anyone other than Us; or (iii) the combination, operation, or use of the Service(s) with other data, hardware, or software not provided by Us. If Your use of the Service(s) results or in Our opinion is likely to result in an IP Claim, We may at Our own option and expense (a) procure for You the right to continue using the foregoing items as set forth hereunder; (b) replace or modify them to make them non-infringing; or (c) if options (a) or (b) are not commercially reasonably as determined by Us, then either You or We may terminate Your’s subscription to the Service(s), and We shall refund You, on a pro-rated basis, any subscription charges that You have previously paid for the corresponding unused portion. This section states Our entire liability and Your exclusive remedy with respect to an IP Claim."
      }
    ]
  },
  {
    id: "13",
    title: "Miscellaneous",
    points: [
      {
        pointTitle: "Entire agreement and revisions",
        pointDescription: "These terms, including all schedules and online policies incorporated herein by reference, contain the entire agreement and understanding of the parties and supersede all prior communications, discussions, negotiations, proposed agreements, and all other agreements between them, whether written or oral, concerning the subject matter herein. We may amend these terms from time to time, in which case the new terms will supersede prior versions. We will notify you not less than ten (10) days prior to the effective date of any amendments to these terms, and your continued use of the service(s) following the effective date of any such amendment may be relied upon by us as your acceptance of any such amendment."
      },
      {
        pointTitle: "Relationship of the parties",
        pointDescription: "The parties are independent contractors. These terms do not create a partnership, franchise, joint venture, agency, fiduciary, or employment relationship among the parties."
      },
      {
        pointTitle: "Assignment",
        pointDescription: "Except to your affiliates/within your group companies, you may not, directly or indirectly, assign all or any part of these terms or your respective rights under these terms or delegate performance of its respective duties under these terms without the prior consent, which consent shall not be unreasonably withheld, of us. In the event of assignment to an affiliate, the party assigning its performance shall promptly inform the other party of such assignment and shall not default in any of its payment obligations under these terms. Subject to the foregoing restrictions, these terms will be fully binding upon, inure to the benefit of, and be enforceable by the parties and their respective successors and assigns."
      },
      {
        pointTitle: "Force majeure",
        pointDescription: "Notwithstanding anything to the contrary contained elsewhere, we shall not be liable for unavailability of the service(s) caused by circumstances beyond our reasonable control, such as but not limited to, acts of god, acts of government, pandemic, epidemic acts of terror or civil unrest, technical failures beyond our reasonable control (including, without limitation, inability to access the internet, unauthorized loss, distribution or dissemination of service data), or acts undertaken by third parties, including without limitation, distributed denial of service attacks."
      },
      {
        pointTitle: "Governing law and dispute resolution",
        pointDescription: "These terms shall be governed by the laws of the state of Delaware. You hereby expressly agree to submit to the exclusive personal jurisdiction of the courts in Delaware. Any dispute, claim, or controversy arising out of or relating to these terms or the breach, termination, enforcement, interpretation, or validity thereof, including the determination of the scope or applicability of these terms to arbitrate, shall be first settled by arbitration administered by JAMS pursuant to its arbitration rules and procedures. Judgment on the award may be entered in any court having jurisdiction. This clause will not preclude the parties from seeking provisional remedies in aid of arbitration from a court of appropriate jurisdiction. The language of the arbitration shall be English, and the seat shall be the state of Delaware."
      },
      {
        pointTitle: "Export compliance",
        pointDescription: "The service(s) and documentation thereof may be subject to export laws and regulations of the U.S. and other applicable jurisdictions. You represent and warrant that you are not on any U.S. government prohibited list. You will not permit any user to access or use the services or documentation in a country or region that is embargoed by the U.S. or other applicable jurisdictions or in violation of any export law or regulation of the U.S. or other applicable jurisdictions."
      },
      {
        pointTitle: "Publicity rights",
        pointDescription: "You hereby grant us a royalty-free, worldwide, transferable license to use your trademark or logo to identify you as our customer on our websites and/or marketing collateral."
      },
      {
        pointTitle: "Notices and consent to electronic communications",
        pointDescription: "All notices to be provided by us to you under these terms may be delivered in writing (i) by nationally recognized overnight delivery service(s) (“courier”) or to the contact mailing address provided by you in the relevant order form; or (ii) electronic mail to the e-mail address provided by you. Our address for a notice to us: (i) in writing by courier is Botgauge Inc 1111b S Governors Ave Ste 3608 Dover, DE 19904 or (ii) by electronic mail is contact@botgauge.com. All notices shall be deemed to have been given immediately upon delivery by electronic mail, or if otherwise delivered upon receipt or, if earlier, two (2) business days after being deposited in the mail or with a courier as permitted above."
      },
      {
        pointTitle: "Survival",
        pointDescription: "All clauses which, by their nature, are intended to survive, including without limitation clauses 5 (intellectual property rights), 7 (charges and payment), 8 (term and termination), 9 (confidentiality; security and data privacy), 10 (disclaimer of warranties), 11 (limitation of liability), 12 (indemnification), and 13 (miscellaneous), shall survive any termination of these terms with respect to use of the service(s) by you. Termination shall not limit either party’s liability for obligations accrued as of or prior to such termination or for any breach of these terms."
      }
    ]
  }

]

// terms top
export const termsTop = [
  {
    id: "1",
    title: "Definitions:",
    description: "When used in these Terms with initial capital letters, in addition to terms defined elsewhere in these Terms, the following definitions apply:",
    bulletPoints:[
      {
        bulletPointTitle:"Account:",
        bulletPointDes:"Refers to any accounts or instances created by You or on Your behalf for access and use of the Service(s)."
      },
      {
        bulletPointTitle:"API:",
        bulletPointDes:"Refers to the application programming interfaces developed, enabled by, or licensed to us that allow access to certain functionalities provided by the Service(s)."
      },
      {
        bulletPointTitle:"Confidential Information:",
        bulletPointDes:"Refers to all information disclosed by one Party to the other Party that is in tangible form and labeled “confidential” (or with a similar legend) or which a reasonable person would understand to be confidential given the nature of the information and circumstances of disclosure. For the purposes of these Terms, Service Data is deemed Confidential Information. However, Confidential Information does not include any information which: (a) was publicly known and made generally available in the public domain before disclosure by the disclosing party; (b) becomes publicly known and made generally available after disclosure by the disclosing party to the receiving party through no action or inaction of the receiving party; (c) is already in the possession of the receiving party at the time of disclosure by the disclosing party as shown by the receiving party’s files and records before the time of disclosure; (d) is obtained by the receiving party from a third party without breaching the third party’s obligations of confidentiality; (e) is independently developed by the receiving party without using or referring to the disclosing party’s Confidential Information, as shown by documents and other competent evidence in the receiving party’s possession; or (f) is required by law to be disclosed by the receiving party, provided that the receiving party, to the extent legally permitted, gives the disclosing party written notice of such requirement before disclosing, so that the disclosing party may seek a protective order or other appropriate relief."
      },
    ]
  },
  {
    id:"2",
    title:"Documentation:",
    description:"Refers to any written or electronic materials, images, videos, text, or sounds that specify the functionalities of the Service(s) provided or made available by Us to You or Your Users through the Service(s) or by other means."
  },
  {
    id:"3",
    title:"Fees:",
    description:"Refers to the payment required for a Subscription."
  },
  {
    id:"4",
    title:"Intellectual Property Rights:",
    description:"Includes all intellectual property rights and analogous rights existing under the laws of any jurisdiction worldwide, including all extensions and renewals of such rights, whether registered or not, encompassing copyrights, trademarks, trade names, service marks, service names, patents, designs, and all other proprietary rights, regardless of protection status."
  },
  {
    id:"5",
    title:"Order Form:",
    description:"Refers to any service order form or statement of work specifying the Service(s) subscribed to, including particular features and functionalities in the Service(s) that You wish to avail of and the Subscription Term."
  },
  {
    id:"6",
    title:"Personal Data:",
    description:"Refers to data relating to a living individual who can be identified either from the data alone or from the data in conjunction with other information that is in, or is likely to come into, the possession of the data controller."
  },
  {
    id:"7",
    title:"Processing/To Process:",
    description:"Refers to any operation or set of operations performed upon Personal Data, whether or not by automatic means, such as collection, recording, organization, storage, adaptation, alteration, retrieval, consultation, use, disclosure by transmission, dissemination, or otherwise making available, alignment, combination, blocking, erasure, or destruction."
  },
  {
    id:"8",
    title:"Sensitive Personal Information:",
    description:"Refers to information relating to an individual’s racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data, biometric data for uniquely identifying a natural person, data concerning health, or data concerning a natural person's sex life or sexual orientation. It also includes information about an individual's criminal offenses or convictions, as well as any other information deemed sensitive under applicable data protection laws."
  },
  {
    id:"9",
    title:"Service(s):",
    description:"Refers to all electronic data, text, messages, emails, personal data, or other materials, including Personal Data of Users and end users, submitted to the Service(s) by You through Your Account or through integration with Third-Party Service(s) in connection with Your use of the Service(s)."
  },
  {
    id:"10",
    title:"Software:",
    description:"Refers to software provided by Us accessed through the internet that allows You to use any functionality in connection with the Service(s)."
  },
  {
    id:"11",
    title:"Subscription Term:",
    description:"Refers to the period during which You have agreed to subscribe to the Service(s) as specified in the subscription plan or in a relevant Order Form."
  },
  {
    id:"12",
    title:"Third-Party Service(s):",
    description:"Refers to third-party applications or services integrating with the Service(s) through APIs or otherwise enabled through the Service(s) which require You to have Your own accounts with such third-party applications or services to utilize them."
  },
  {
    id:"13",
    title:"User:",
    description:"Refers to those who are designated users within the Service(s), including an Account administrator, agents, and other designated users."
  },
  {
    id:"14",
    title:"Website(s):",
    description:"Refers to the websites owned and operated by Us, including [https://botgauge.io/]."
  }
]


export const TermsPrivacyPolicy = [
  {
    id: "1",
    title: "Information Collection and Use",
    description: "Details the collection and usage of Personal Information for account registration, service usage data, automatic data collection, payment information, and other voluntarily submitted information."
  },
  {
    id: "2",
    title: "Purpose of Use of the Information",
    description: "Explains the primary goals of collecting information, including provision of the service, customer support, communications, research and development, dispute resolution, compliance with laws, payment processing, and machine learning."
  },
  {
    id: "3",
    title: "Information Sharing with Third Parties",
    description: "Specifies conditions under which Customer Information may be shared with third parties, emphasizing compliance with applicable laws and exceptions."
  },
  {
    id: "4",
    title: "Cookies",
    description: "Describes the use of cookies for account identification, user interaction, usage monitoring, and advertisement campaigns, with options for users to manage cookie acceptance in their browsers."
  },
  {
    id: "5",
    title: "Third-Party Service Providers",
    description: "Discusses the sharing of information with third parties for services such as payment processing, data analysis, email delivery, hosting, customer service, and marketing."
  },
  {
    id: "6",
    title: "Deletion of Your Information",
    description: "Outlines users' rights to review, update, correct, or delete Personal Information, including the process for account deletion and retention periods."
  },
  {
    id: "7",
    title: "Links to Other Websites",
    description: "Warns about third-party website links on the site, advises users to review privacy policies of external sites, and disclaims endorsement or control over third-party practices."
  },
  {
    id: "8",
    title: "Policy Updates",
    description: "Notifies users of periodic updates to the Privacy Policy, methods of notification, and user agreement with revised policies."
  },
  {
    id: "9",
    title: "Security",
    description: "Addresses security measures implemented to protect Personal Information, acknowledges limitations of internet security, and outlines procedures for handling security breaches."
  },
  {
    id: "10",
    title: "Our Policy Towards Children",
    description: "States that the service is not intended for individuals under 18 years old, outlines restrictions on collecting information from children under 13, and provides a contact method for parental inquiries."
  }
];

// terms data
export const privacyPolicyData = [
  {
    id: "1",
    title: "Information Collection and Use",
    points: [
      {
        pointTitle: "Personal Information for Account Registration:",
        pointDescription: "To register for an account, we collect your name, email address, and password. You may also provide additional information such as your company name, phone number, and billing address."
      },
      {
        pointTitle: "Data for Service Usage:",
        pointDescription: "Before you can use the Service after registering your account, you must provide us with information about the device (e.g., the Internet Protocol address of your computer) or the website (e.g., URL) through which you will access the Service. You must also provide any data we may require for the performance of the Service, such as test scenarios and test plans."
      },
      {
        pointTitle: "Data Automatically Collected During the Use of the Service:",
        pointDescription: "When you use the Service, our servers may automatically collect and record information that your browser sends to us through “cookies” (small data files transferred to your computer’s hard disk for record-keeping purposes), heat maps, web beacons, and log files (“Log Data”). Log Data may include information such as your computer’s Internet Protocol (IP) address, your usage history of the Service, browser type, the webpage you visited before or during your use of the Service, time spent on those pages, and other information related to your computers or mobile devices."
      },
      {
        pointTitle: "Payment Information:",
        pointDescription: "We may also collect information necessary for processing payments for your use of the Service. For more details, please refer to our Terms of Service."
      },
      {
        pointTitle: "Other Information:",
        pointDescription: "In addition to the above, we may collect and record information that you voluntarily submit to us."
      }
    ]
  },
  {
    id: "2",
    title: "Purpose of Use of the Information",
    description: "Our primary goals in collecting information are to provide and improve our Service, features, and content, to manage your use of the Service, and to assist you and our other customers in using the Service effectively. Specifically, we use your information ('Customer Information') in the following ways:",
    points: [
      {
        pointTitle: "Provision of the Service and Customer Support:",
        pointDescription: "We use your Customer Information to deliver the Service and for customer support purposes, such as responding to your inquiries. Additionally, we may use your Personal Information to contact you regarding your use of the Service."
      },
      {
        pointTitle: "Announcements by BotGauge:",
        pointDescription: "We use your Customer Information to communicate with you through newsletters, marketing, promotional materials, and other information that may interest you."
      },
      {
        pointTitle: "Research and Development:",
        pointDescription: "We may use your Customer Information to monitor and analyze the use of the Service, for technical administration, to enhance our Service’s functionality and user-friendliness, and to better tailor our Service to meet our customers’ needs."
      },
      {
        pointTitle: "Use for Dispute Resolution, Compliance with Laws and Government Orders:",
        pointDescription: "We may use Customer Information to investigate and address any claims or disputes related to the Service, as permitted by applicable laws or required by orders from government authorities."
      },
      {
        pointTitle: "Payment Procedure:",
        pointDescription: "We may use your Customer Information to process payments for Service fees."
      },
      {
        pointTitle: "Machine Learning:",
        pointDescription: "We may combine your Customer Information, including Personal Information, with other data collected during your use of the Service for deep learning processes. This is intended to enhance your experience, improve the quality and value of the Service, and analyze and understand how our Service is used. However, data used in deep learning will not be aggregated with information collected from other customers."
      }
    ]
  },
  {
    id: "3",
    title: "Information Sharing with Third Parties",
    points: [
      {
        pointTitle: "Sharing Information:",
        pointDescription: "We will not share your Customer Information with third parties without your consent unless permitted by applicable laws. An exception to this is the sharing of information between Autify, Inc., incorporated in the United States."
      }
    ]
  },
  {
    id: "4",
    title: "Cookies",
    points: [
      {
        pointTitle: "Purpose of Cookies:",
        pointDescription: "We use cookies to identify your account, understand how you interact with the Service, monitor aggregate usage, and manage advertisement campaigns. Third-party advertisers on our site may also place or read cookies on your browser."
      },
      {
        pointTitle: "Cookie Management:",
        pointDescription: "You can instruct your browser to stop accepting cookies or to prompt you before accepting a cookie. However, if you do not accept cookies, you may not be able to use all portions of our site or functionalities of the Service."
      }
    ]
  },
  {
    id: "5",
    title: "Third-Party Service Providers",
    points: [
      {
        pointTitle: "Sharing Information with Third Parties:",
        pointDescription: "We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance."
      }
    ]
  },
  {
    id: "6",
    title: "Deletion of Your Information",
    points: [
      {
        pointTitle: "Review and Deletion:",
        pointDescription: "You have the right to review, update, correct, or delete your Personal Information by contacting us. If you wish to cancel your account and delete your Customer Information, please delete your account on the Service site. Once your account is deleted, your Customer Information will be removed immediately or after a retention period, provided that we do not have any legal obligations or legitimate business reasons to retain the information."
      }
    ]
  },
  {
    id: "7",
    title: "Links to Other Websites",
    points: [
      {
        pointTitle: "Third-Party Links:",
        pointDescription: "Our Site contains links to other websites. Clicking these links directs you to third-party websites, which may have their own privacy policies. We do not control or endorse these sites and encourage you to review their privacy policies."
      }
    ]
  },
  {
    id: "8",
    title: "Policy Updates",
    points: [
      {
        pointTitle: "Updates to Privacy Policy:",
        pointDescription: "This Privacy Policy may be updated periodically. Material changes will be communicated via email or notice on the site. By continuing to use the Service after changes, you agree to the updated Privacy Policy."
      }
    ]
  },
  {
    id: "9",
    title: "Security",
    points: [
      {
        pointTitle: "General Security Measures:",
        pointDescription: "We use SSL encryption and industry standards to protect your Personal Information. However, no method of transmission or storage is 100% secure. We strive to protect your information but cannot guarantee its absolute security."
      },
      {
        pointTitle: "Phishing Awareness:",
        pointDescription: "We prioritize protecting against identity theft and phishing. We never request sensitive information via non-secure or unsolicited communications."
      }
    ]
  },
  {
    id: "10",
    title: "Our Policy Towards Children",
    points: [
      {
        pointTitle: "Children's Privacy:",
        pointDescription: "Our Service is not intended for individuals under 18, and we do not knowingly collect information from children under 13. If such information is collected, we will promptly delete it upon request from a parent or guardian."
      }
    ]
  }
];
