import React from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Home from './pages/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import LocomotiveScroll from 'locomotive-scroll';
import './index.css';
import BlogsPage from './pages/BlogsPage/BlogsPage';
import SingleBlog from './components/BlogPage/SingleBlog';
import RequestDemo from './pages/RequestDemo/RequestDemo';
import Products from './pages/Products/Products';
import Terms from './pages/Terms/Terms';
import ContactUs from './pages/ContactUs/ContactUs';
import PrivacyPoilcy from './pages/PrivacyPolicy/PrivacyPoilcy';

const App = () => {
  const [isUpdateOpen, setIsUpdateOpen] = React.useState(true);


  const location = useLocation();
  const routes = useRoutes([
    { path: "/", element: <Home resize={isUpdateOpen?false:true} /> },
    { path: "/blogs", element: <BlogsPage resize={isUpdateOpen?false:true} />},
    { path: "/blogs/:slug", element: <SingleBlog resize={isUpdateOpen?false:true} />},
    { path: "/product", element: <Products resize={isUpdateOpen?false:true} />},
    { path: "/request-a-demo", element: <RequestDemo resize={isUpdateOpen?false:true} />},
    { path: "/terms", element: <Terms resize={isUpdateOpen?false:true} />},
    { path: "/privacy", element: <PrivacyPoilcy resize={isUpdateOpen?false:true} />},
    { path: "/contact", element: <ContactUs resize={isUpdateOpen?false:true} />},
  ]);
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  
  React.useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: document.querySelector('.main-container'),
      smooth: true
    });

    return () => scroll.destroy();
  }, []);

  if (!routes) return null;

  return (
    <div className='main-container'>

      <Navbar isUpdateOpen={false} setIsUpdateOpen={setIsUpdateOpen} />
      {/* animation on route changes */}
      <AnimatePresence mode='wait' initial={false}>
        {React.cloneElement(routes, { key: location.pathname })}
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default App;
