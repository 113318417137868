import React, { useState } from 'react';
import { updates } from '../../utils/data';
import cross from '../../assets/cross-icon.svg';
import './Updates.css';
import { Link } from 'react-router-dom';

const Updates = ({isUpdateOpen, setIsUpdateOpen}) => {

  // const initialUpdateId = localStorage.getItem('botgauge_last_update');
  // const [isUpdateOpen, setIsUpdateOpen] = useState(true); 


  const latestItem = updates.reduce((prev, current) => {
    return prev.id > current.id ? prev : current;
  });
  const words = latestItem.title.split(' ');
  const shortenedTitle = words.slice(0, 5).join(' ');
  const displayedTitle = shortenedTitle + (words.length > 5 ? '...' : '');

  const handleClose = () => {
    // localStorage.setItem('botgauge_last_update', latestItem.id);
    setIsUpdateOpen(false);
  };

  if (!isUpdateOpen) {
    return null;
  }

  return (
    <div className='updates-container'>
      <span>Updates: </span>
      <p>{displayedTitle}</p>
      <Link onClick={handleClose} to='https://calendly.com/botgauge/30min'>know more</Link>
      <img className='close-updates' width={16} height={16} src={cross} alt="close updates" onClick={handleClose} />
    </div>
  );
}

export default Updates;
