import './Navbar.css';
import Logo from '../../assets/BotGauge-logo.png';
import LogoWhite from '../../assets/BotGauge-logo-white.png';
import { navbarLinks } from '../../utils/data';
import { Link, NavLink } from 'react-router-dom';
import Updates from '../Updates/Updates';
import { AnimatePresence, animate, delay, motion } from 'framer-motion';

import menu from '../../assets/navbar-icon.svg';
import { useState } from 'react';

const Navbar = ({isUpdateOpen, setIsUpdateOpen}) => {

  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  }

  const handleMenuClose = () => {
    setMenuOpen(false);
  }

  const hamburgerMenuVariants = {
    initial: {
      scaleY: 0,
    },
    animate: {
      scaleY: 1,
      transition:{
        duration: 0.5,
        ease: [0.12, 0, 0.39, 0],
      }
    },
    exit: {
      scaleY: 0,
      transition:{
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1],
      }
    }
  }

  const mobileLinkVariants = {
    initial: {
      opacity: 0
    },
    animate: {
      opacity: 1,
      transition:{
        duration: 0.5,
        ease: [0.65, 1, 0.35, 1],
        delay: 0.4,
      }
    },
    exit: {
      opacity: 0,
      transition:{
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1],
        delay: -0.4,
      }
    }
  }

  return (
    <div className='navbar-container'>
      <nav className='navbar'>
        <div className='navbar-logo'>
          <Link style={{display:'flex', alignItems:'center',justifyContent:"center"}} to='/'><img src={Logo} alt="BotGauge" /></Link>
        </div>
        <div className='navbar-links'>
          {navbarLinks.map((link, index) => (
            <NavLink key={index} to={link.path}>{link.title}</NavLink>
          ))}
        </div>
        <div className='navbar-buttons'>
          {/* <Link to='https://calendly.com/botgauge/30min'>Sign In</Link> */}
          <Link className='' to='/contact'>Contact Us</Link>
          <Link className='' to='https://calendly.com/botgauge/30min'>Book a demo</Link>
        </div>
        <div className='menu-icon'>
          <img src={menu} alt="menu" onClick={handleMenuOpen} />
        </div>
      </nav>
      <AnimatePresence>

        {menuOpen &&

          <motion.div
            variants={hamburgerMenuVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            className='hamburger-menu'>
            <div className='hamburger-menu-top'>
              <motion.div variants={mobileLinkVariants} className='hamburger-menu-logo'>
                <img width={130} src={LogoWhite} alt="BotGauge" />
              </motion.div>
              <motion.p 
                style={{userSelect:"none"}}
                variants={mobileLinkVariants} 
                className='close-icon' 
                onClick={handleMenuClose}
                >Close
              </motion.p>
            </div>
            <div 
              className='hamburger-menu-links'>
              {navbarLinks.map((link, index) => (
                <motion.div 
                key={index}
                  variants={mobileLinkVariants}
                  initial='initial'
                  animate='animate'
                  exit='exit'
                className='hamburger-link-container'>
                  <NavLink key={index} to={link.path} onClick={handleMenuClose}>{link.title}</NavLink>
                </motion.div>
              ))}
              <div className='hamburger-menu-signin-container'>
                {/* <motion.div 
                  variants={mobileLinkVariants}
                  initial='initial'
                  animate='animate'
                  exit='exit'
                  whileTap={{scale: 0.9}}
                  className='hamburger-button-container'>
                  <Link to='https://calendly.com/botgauge/30min' onClick={handleMenuClose}>Sign In</Link>
                </motion.div> */}
                <motion.div 
                  variants={mobileLinkVariants}
                  initial='initial'
                  animate='animate'
                  exit='exit'
                  whileTap={{scale: 0.9}}
                  className='hamburger-button-container'>
                  <Link to='https://calendly.com/botgauge/30min' onClick={handleMenuClose}>Book a demo</Link>
                </motion.div>
              </div>
            </div>
            <div className='hamburger-menu-bottom'>
              <motion.p variants={mobileLinkVariants}>© 2024 BotGauge | Privacy | Terms | Security</motion.p>
            </div>

          </motion.div>
        }
      </AnimatePresence>

      <Updates isUpdateOpen={isUpdateOpen} setIsUpdateOpen={setIsUpdateOpen} />
    </div>
  )
}

export default Navbar